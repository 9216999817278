@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap');
// @import 'normalize.css';

// https://css-tricks.com/elegant-fix-jumping-scrollbar-issue/
html {
  // margin-left: calc(100vw - 100%);
  overflow-y: scroll;
}

.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  display: inline-block;
  border-style: none;
  border-width: 0;
  border-color: #000 #000 #c6c6c6;
  width: 20px;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 33px;
  // margin-bottom: 16px;
  // padding-top: 0px;
  // padding-left: 0px;

  // height: 33px;
  // margin: 0;
  font-size: 14px;
  line-height: 20px;
  border: 0; //1px solid #eee;
  // min-width: 30px;
  // width: 100px;
  // max-width: 90%;
  // border: 1px solid black;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  // border: 1px solid #ddd;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.03em;
  display: inline-block;
  padding: 4px 12px;
  margin: 4px 5px;
  border-radius: 20px;
  border: 1px none #000;
  background-color: #f2f2f2;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.ReactTags__selected span.ReactTags__tag:first-child {
  margin-left: 0px;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  margin-left: 4px;
  // font-size: 18px;
  // font-weight: 200;
  // color: white;
}

.calculator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  margin: 40px auto;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 22px;
  background-color: #f4f4f4;
  box-shadow: inset 5px 4px 4px 1px #fff, inset -1px -1px 7px 3px rgba(0, 0, 0, 0.15),
    5px 4px 5px -6px hsla(0, 0%, 72.2%, 0.8), 9px 7px 30px -16px rgba(0, 0, 0, 0.48);
}

.h5_center_grey {
  width: 350px;
  margin-right: auto;
  margin-left: auto;
  color: #9f9f9f;
  font-size: 9px;
  line-height: 14px;
  font-weight: 300;
  text-align: center;
}

.cal_mic {
  width: 32px;
  height: 6px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 20px;
  background-color: #f4f4f4;
  box-shadow: inset -3px -3px 5px 0 #fff, inset 2px 2px 5px 2px rgba(0, 0, 0, 0.05),
    inset 1px 1px 3px 0 rgba(0, 0, 0, 0.11);
}

.phone_no_field {
  width: 100%;
  height: 42px;
  margin-bottom: 16px;
  padding-right: 12px;
  padding-left: 12px;
  border: 1px none #000;
  border-radius: 7px;
  background-color: #f4f4f4;
  box-shadow: inset -4px -4px 7px 0 #fff, inset 3px 3px 6px 3px rgba(0, 0, 0, 0.05),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.11);
  color: rgba(74, 74, 74, 0.8);
  font-size: 20px;
  text-align: left;
  letter-spacing: 1px;
}

.dial_pad_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
}

.dial_button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 48px;
  margin-bottom: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 9px;
  background-color: #f4f4f4;
  box-shadow: -4px -3px 6px -3px hsla(0, 0%, 100%, 0.89), -4px -4px 10px 3px hsla(0, 0%, 100%, 0.82),
    1px 1px 8px -2px rgba(18, 18, 18, 0.16), 4px 7px 15px 2px rgba(0, 0, 0, 0.1);
  color: rgba(74, 74, 74, 0.8);
  font-size: 20px;
  cursor: pointer;
}

.dial_button:active {
  box-shadow: inset -4px -4px 7px 0 #fff, inset 3px 3px 6px 1px rgba(0, 0, 0, 0.09),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.11);
}

.force_100_vh {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
